import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import whyus from '../../Assets/about/about.jpg'
function About() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    return (
        <section className="md:my-12 urbanist md:py-6 px-4 md:px-8">
            <div className="mx-auto flex flex-col-reverse lg:flex-row lg:space-x-8 items-center justify-between">
                <div className="lg:w-1/2 mt-8 lg:mt-0" data-aos="fade-right">
                    <h2
                        className="font-bold drop-shadow-md"
                        style={{ fontSize: "clamp(20px, 2vw, 32px)" }}
                    >
                        Chitral Green Guest House
                    </h2>
                    <p
                        className="mt-4"
                        style={{ fontSize: "clamp(16px, 1.5vw, 23px)" }}
                    >
                        Chitral Green Guest House is the perfect retreat in the heart of Chitral. As a top-rated guest house in Chitral, we are here to make your stay comfortable and memorable. Whether you’re visiting for a vacation or a business trip, our guest house offers the right balance of peace and convenience. With our central location, you can explore the beauty of Chitral while enjoying the luxury and privacy you deserve.
                    </p>
                    <p
                        className="mt-4"
                        style={{ fontSize: "clamp(16px, 1.5vw, 23px)" }}
                    >
                        We focus on hospitality and attention to detail to ensure a relaxing stay. From affordable rooms to top-level services, we meet the needs of all our guests. This makes Chitral Green Guest House the best guest house in Chitral. Enjoy a mix of traditional charm and modern comfort that sets us apart from other guest houses in Chitral.

                    </p>
                </div>

                <div className=" w-full lg:w-1/2 mt-8 md:mt-0" data-aos="fade-left">
                    <img
                        src={whyus}
                        alt="Chitral Green Guest House"
                        className="w-full h-auto object-cover rounded-lg shadow-md"
                    />
                </div>
            </div>
        </section>
    );
}

export default About;
