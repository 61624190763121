import React from "react";
import img1 from "../../Assets/bestplaces/2.jpeg";
import img2 from "../../Assets/bestplaces/4.jpeg";
import img3 from "../../Assets/bestplaces/1.jpeg";
import img4 from "../../Assets/bestplaces/7.jpeg";
import img5 from "../../Assets/bestplaces/3.jpeg";
import img6 from "../../Assets/bestplaces/5.jpeg";
import img7 from "../../Assets/bestplaces/6.jpeg";
import img8 from "../../Assets/bestplaces/8.jpeg";
import img9 from "../../Assets/bestplaces/10.jpeg";
import img10 from "../../Assets/bestplaces/9.jpeg";
const BestPlaces = () => {
  const images = [
    {
      src: img1,
      title: "Chitral Fort",
      description:
        "A historical landmark in the heart of Chitral Valley, the Chitral Fort stands as a testament to the region's rich cultural heritage. Built centuries ago, this majestic fort has witnessed the rise and fall of many empires and remains a popular tourist attraction.",
    },
    {
      src: img9,
      title: "Terich Mir Mountain",
      description:
        "The tallest peak in the Hindu Kush mountain range, Terich Mir towers above the landscape at an awe-inspiring 7,708 meters. It is a favorite destination for mountaineers and adventurers seeking the challenge of its rugged terrain and breathtaking views.",
    },
    {
      src: img2,
      title: "Kalash Festival",
      description:
        "The vibrant Kalash Festival is a cultural celebration of the indigenous Kalash people, showcasing their unique traditions, music, and dance. Held annually in the remote valleys of Chitral, it offers a glimpse into the colorful and ancient heritage of this small but proud community.",
    },
    {
      src: img10,
      title: "Chitral Gol National Park",
      description:
        "Chitral Gol National Park is a renowned wildlife sanctuary in Chitral, home to a variety of rare and endangered species, including the famous snow leopard and markhor. The park is a haven for wildlife enthusiasts and trekkers, offering stunning landscapes and rich biodiversity.",
    },
    {
      src: img4,
      title: "Shandur Polo Festival",
      description:
        "Held at the world's highest polo ground in Shandur, this annual polo festival is a thrilling event that brings together players and spectators from across the region. Set against the stunning backdrop of the Himalayas, it is a spectacular display of skill, tradition, and sportsmanship.",
    },
    {
      src: img3,
      title: "Broghil Polo Festival",
      description:
        "The Broghil Polo Festival is a traditional polo match set in the remote and scenic Broghil Valley. It celebrates the region's deep-rooted connection to the sport of polo, with local teams competing in an atmosphere filled with excitement and camaraderie.",
    },
    {
      src: img5,
      title: "Garam Chasma",
      description:
        "Garam Chasma, or 'Hot Springs,' is a natural hot spring located near Chitral town. Known for its therapeutic waters, the springs attract visitors seeking relaxation and healing in the serene and tranquil surroundings of the Chitral Valley.",
    },
    {
      src: img6,
      title: "Nagroagh Beauty",
      description:
        "Nagroagh is renowned for its stunning scenic views, offering a picturesque landscape of lush green valleys and towering mountains. It's a paradise for nature lovers and photographers, providing an idyllic escape into the heart of Chitral's natural beauty.",
    },
    {
      src: img7,
      title: "Qaqlasht Meadows",
      description:
        "The Qaqlasht Meadows are a sprawling expanse of vibrant greenery, nestled in the Qaqlasht region. These meadows provide a perfect spot for picnics, hiking, and enjoying panoramic views of the surrounding mountains and valleys, particularly during the spring season when they are in full bloom.",
    },
    {
      src: img8,
      title: "Siwakth Hutts",
      description:
        "The rustic huts of Siwakth Valley offer a glimpse into the simple, traditional lifestyle of the local inhabitants. Surrounded by untouched wilderness, these huts provide a tranquil retreat for those looking to disconnect from modern life and immerse themselves in nature.",
    }
  ];


  return (
    <div className="my-24 px-4 md:px-8">
      <h2
        className="font-bold urbanist text-center drop-shadow-md"
        data-aos="fade-right"
        style={{ fontSize: "clamp(18px, 2vw, 32px)" }}
      >
        Intresting Places in Chitral to Explore
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-4">
        {images.map((image, index) => (
          <div key={index} data-aos="fade-up" className="w-full py-3">
            <img
              src={image.src}
              alt={image.title}
              className="w-full h-[20rem]  object-cover rounded-lg shadow-md"
            />
            <p className="mt-2 text-2xl font-bold">
              {image.title}
            </p>
            <p className="mt-1 text-lg text-gray-600 font-medium">
              {image.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BestPlaces;
